<template>
  <v-footer id="footer-basic" color="#ffffff" dark>
    <v-container fluid>
      <v-row justify="center">
        <base-img
          contain
          :src="require('@/assets/bistrosoft-logo-white-with-copy.png')"
          max-width="8rem"
          max-height="3rem"
        />
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "FooterBasic",
};
</script>

<style lang="sass">
#footer-basic
  background-position: center bottom
  a
    text-decoration: none
</style>

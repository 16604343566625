import { render, staticRenderFns } from "./FooterBasic.vue?vue&type=template&id=b6e4672a"
import script from "./FooterBasic.vue?vue&type=script&lang=js"
export * from "./FooterBasic.vue?vue&type=script&lang=js"
import style0 from "./FooterBasic.vue?vue&type=style&index=0&id=b6e4672a&prod&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports